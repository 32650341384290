import SearchPagination from 'portaltransparencia-common/components/SearchPagination.react.js';
import TableActions from 'portaltransparencia-common/components/TableActions.react.js';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react';
import TableHeaderItem from 'portaltransparencia-common/components/TableHeaderItem.react';
import DateUtils from 'portaltransparencia-common/libs/DateUtils.js';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';

import ConsultaProcessoDetalhe from './FormConsultaDetalhe.react.js';

export class FormConsultaProcesso extends Component {
  static propTypes = {
    consultaProcesso: PropTypes.object,
    processoTramitacao: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    page: PropTypes.object,
    filtros: PropTypes.string,
    searchWithPage: PropTypes.func,
    findProcesso: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      showDetail: false
    };
  }

  openDetail = () => {
    this.setState({
      showDetail: true
    });
  };

  onCloseDetail = () => {
    this.setState({
      showDetail: false
    });
  };

  onViewDetalhes = processo => {
    const { findProcesso } = this.props;

    const entidade = processo.get('entidade');
    const exercicio = processo.get('exercicio');
    const numero = processo.get('processo');
    const tipo = processo.get('tipo');

    const filtro = `entidade=${entidade}&exercicio=${exercicio}&numero=${numero}&tipo=${tipo}`;

    findProcesso(filtro).then(() => this.openDetail());
  };

  renderConsultaESicRow = sic => {
    return (
      <TableActions
        ref="row"
        key={sic.get('processo')}
        data={sic}
        onView={this.onViewDetalhes}
      >
        <td>{DateUtils.formatDateTimeShort(sic.get('data'))}</td>
        <td>{sic.get('processo') + '/' + sic.get('exercicio')} </td>
        <td>{sic.get('requerente')}</td>
        <td>{sic.get('digitacao') && sic.get('digitacao')} </td>
        <td>{sic.get('situacao') && sic.get('situacao')}</td>
        <td>{sic.get('grauSigilo') && sic.get('grauSigilo')}</td>
        <td>{sic.get('assunto') && sic.get('assunto')}</td>
      </TableActions>
    );
  };

  render() {
    const {
      consultaProcesso,
      page,
      searchWithPage,
      processoTramitacao
    } = this.props;
    const { showDetail } = this.state;
    if (!consultaProcesso) {
      return null;
    }
    return (
      <div>
        <ConsultaProcessoDetalhe
          processoTramitacao={processoTramitacao}
          onClose={this.onCloseDetail}
          show={showDetail}
        />
        <div className="panel-table-outer">
          <table className="panel-table striped fancy">
            <TableHeader empty={consultaProcesso.isEmpty()}>
              <tr>
                <TableHeaderItem
                  field="data"
                  label="Data"
                  page={page}
                  searchWithPage={searchWithPage}
                />
                <TableHeaderItem
                  field="processo"
                  label="Processo"
                  page={page}
                  searchWithPage={searchWithPage}
                />
                <TableHeaderItem
                  field="requerente"
                  label="Requerente"
                  page={page}
                  searchWithPage={searchWithPage}
                />
                <TableHeaderItem
                  field="digitacao"
                  label="Solicitação"
                  page={page}
                  searchWithPage={searchWithPage}
                />
                <TableHeaderItem
                  field="situacao"
                  label="Situação"
                  page={page}
                  searchWithPage={searchWithPage}
                />
                <TableHeaderItem
                  field="grauSigilo"
                  label="Grau de Sigilo"
                  page={page}
                  searchWithPage={searchWithPage}
                />
                <TableHeaderItem
                  field="assunto"
                  label="Assunto/Motivo"
                  page={page}
                  searchWithPage={searchWithPage}
                />
                <th></th>
              </tr>
            </TableHeader>
            <tbody>{consultaProcesso.map(this.renderConsultaESicRow)}</tbody>
          </table>
        </div>
        <SearchPagination
          page={page}
          searchWithPage={searchWithPage}
          showTotalCount={true}
        />
      </div>
    );
  }
}

export default FormConsultaProcesso;
