import { Map } from 'immutable';
import DatePicker from 'portaltransparencia-common/components/DatePicker.react.js';
import Input from 'portaltransparencia-common/components/EloInput.react.js';
import ExportButtonGroup from 'portaltransparencia-common/components/ExportButtonGroup.react.js';
import NumberFormatter from 'portaltransparencia-common/components/NumberFormatter.react.js';
import Panel from 'portaltransparencia-common/components/Panel.react.js';
import SearchPagination from 'portaltransparencia-common/components/SearchPagination.react.js';
import TableActions from 'portaltransparencia-common/components/TableActions.react.js';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react';
import TableHeaderItem from 'portaltransparencia-common/components/TableHeaderItem.react.js';
import configuration from 'portaltransparencia-common/config/Config.js';
import {
  onChange,
  resetCrud
} from 'portaltransparencia-common/crud/Actions.js';
import {
  addFilterUrl,
  addPagination
} from 'portaltransparencia-common/libs/AddFilter.js';
import DateUtils from 'portaltransparencia-common/libs/DateUtils.js';
import NumberUtils from 'portaltransparencia-common/libs/NumberUtils';
import StorageService from 'portaltransparencia-common/libs/StorageService.js';
import { PARAM_CONSULTA_AUTENTICIDADE_SUBVENCOES_TERMOS } from 'portaltransparencia-common/parametros/ParametrosPortalTransparencia';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';

import { downloadArquivo } from '../arquivo/Actions.js';
import FilterButtons from '../components/FilterButtons.react.js';
import PageHeader from '../components/PageHeader.react.js';
import NotasExplicativas from '../notaexplicativa/Form.react.js';
import parametroService from '../parametros/ParametroService.js';
import UltimaAtualizacaoByTable from '../ultimaatualizacao/UltimaAtualizacaoByTable.react.js';
import * as actions from './Actions.js';
import { addErrorNotification } from 'portaltransparencia-common/ui/Actions.js';

export class FormSubvencoes extends Component {
  static propTypes = {
    page: PropTypes.object.isRequired,
    tipoSubvencao: PropTypes.object.isRequired,
    findSubvencoes: PropTypes.func.isRequired,
    findTipoSubvencao: PropTypes.func.isRequired,

    filtros: PropTypes.object,
    filtrosAutenticacao: PropTypes.object,
    onChange: PropTypes.func,
    onChangeFieldValue: PropTypes.func,
    location: PropTypes.object.isRequired,
    resetCrud: PropTypes.func.isRequired,

    anexosCertidao: PropTypes.object.isRequired,
    downloadArquivo: PropTypes.func.isRequired,
    findCertidaoAnexos: PropTypes.func.isRequired,

    paramConsultaAutenticidade: PropTypes.string,
    addErrorNotification: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      isPesquisaAutenticacao: false
    };
  }

  componentDidMount() {
    const {
      findTipoSubvencao,
      findSubvencoes,
      findCertidaoAnexos
    } = this.props;

    findTipoSubvencao();
    findSubvencoes('');
    findCertidaoAnexos('');
  }

  consultarSubvencoes = () => {
    const { page } = this.props;
    const initialPage = page.set('number', 0);

    this.onConsultaWithPage(initialPage);
  };

  onConsultaSubvencoes = () => {
    this.consultarSubvencoes();
    this.refs.panelFiltroSubvencoes &&
      this.refs.panelFiltroSubvencoes.collapse();
  };

  onConsultaWithPage = page => {
    let filter = this.montaFiltro(page);
    const { findSubvencoes } = this.props;

    findSubvencoes(filter);
  };

  onChangeFiltro = event => {
    const { onChange } = this.props;
    onChange('filtroSubvencao', event);
    onChange('filtrosAutenticacao', event);
  };

  onResetFiltro = () => {
    const { findSubvencoes, resetCrud } = this.props;
    resetCrud('filtroSubvencao');

    findSubvencoes('');
  };

  onResetFiltroAutenticacao = () => {
    const { findCertidaoAnexos, resetCrud } = this.props;
    this.setState({
      isPesquisaAutenticacao: false
    });
    resetCrud('filtrosAutenticacao');

    findCertidaoAnexos('');
  };

  onConsultaCertidaoAnexo = () => {
    this.setState({
      isPesquisaAutenticacao: true
    });
    this.consultarCertidaoAnexo();
    this.refs.panelFiltroSubvencoes &&
      this.refs.panelFiltroSubvencoes.collapse();
  };

  consultarCertidaoAnexo = () => {
    const { page } = this.props;
    const initialPage = page.set('number', 0);

    this.onConsultaWithPageCertidaoAnexo(initialPage);
  };

  onConsultaWithPageCertidaoAnexo = page => {
    let filter = this.montaFiltroCertidaoAnexo(page);
    const { findCertidaoAnexos } = this.props;

    findCertidaoAnexos(filter);
  };

  onExporta = (exportType, openTab, windowParams) => {
    const { page, addErrorNotification } = this.props;
    const { apiURL } = configuration;

    if (!page.get('content').isEmpty()) {
      let filtro = this.montaFiltro(page) + '&exportType=' + exportType;

      window.open(
        `${apiURL}/api/subvencoes/report?${filtro}`,
        openTab,
        windowParams
      );
    } else {
      addErrorNotification(
        'A consulta não apresentou resultado. Não é possível exportar para o formato selecionado!'
      );
    }
  };

  onViewSubvencoes = data => {
    const { entidade, exercicio } = StorageService.getItem('userInfo');
    const subvencaoId = data.get('subvencaoId') ? `&subvencaoId=${data.get('subvencaoId')}` : ''; 
    this.props.history.push(
      `/subvencao/detalhe?entidade=${entidade.id}&exercicio=${data.get(
        'exercicio'
      )}&subvencao=${data.get('subvencao')}&anoSubvencao=${data.get(
        'anoSubvencao'
      )}&idTipo=${data.get('idTipo')}`+subvencaoId
    );
  };

  montaFiltro = page => {
    const { entidade } = StorageService.getItem('userInfo');
    let filter = `entidade=${entidade.id}`;

    const { filtros } = this.props;

    if (filtros.get('tipoSubvencao')) {
      filter = addFilterUrl(filter, `tipo=${filtros.get('tipoSubvencao')}`);
    }
    if (filtros.get('cnpj')) {
      filter = addFilterUrl(filter, `cnpj=${filtros.get('cnpj')}`);
    }
    if (filtros.get('nomeBeneficiario')) {
      filter = addFilterUrl(
        filter,
        `nomeBeneficiario=${filtros.get('nomeBeneficiario')}`
      );
    }
    if (filtros.get('dataAssinatura')) {
      filter = addFilterUrl(
        filter,
        `dataAssinatura=${filtros.get('dataAssinatura')}`
      );
    }
    if (filtros.get('objeto')) {
      filter = addFilterUrl(filter, `objeto=${filtros.get('objeto')}`);
    }
    return addPagination(page, filter);
  };

  montaFiltroCertidaoAnexo = page => {
    const { entidade, exercicio } = StorageService.getItem('userInfo');
    let filter = `entidade=${entidade.id}&exercicio=${exercicio.id}`;

    const { filtrosAutenticacao } = this.props;

    if (filtrosAutenticacao.get('chaveAutenticacao')) {
      filter = addFilterUrl(
        filter,
        `chaveAutenticacao=${filtrosAutenticacao.get('chaveAutenticacao')}`
      );
    }
    if (filtrosAutenticacao.get('cnpjAutenticacao')) {
      filter = addFilterUrl(
        filter,
        `cnpj=${filtrosAutenticacao.get('cnpjAutenticacao')}`
      );
    }
    return addPagination(page, filter);
  };

  renderSubvencoes = data => {
    const ValorInicial = !data.get('valorPrevisto')
      ? 0
      : data.get('valorPrevisto');

    const valorAditivo = !data.get('valorAditivo')
      ? 0
      : data.get('valorAditivo');
    const ValorAtual = ValorInicial + valorAditivo;

    const valorLiberado = !data.get('valorLiberado')
      ? 0
      : data.get('valorLiberado');
    const saldoAtualizado = ValorAtual - valorLiberado;

    return (
      <TableActions
        ref="row"
        key={`${data.get('subvencao')}_${data.get('anoSubvencao')}_${data.get(
          'tipoSubvencao'
        )}_${data.get('entidade')}_${data.get('exercicio')}`}
        data={data}
        onMore={this.onViewSubvencoes}
      >
        <th>{data.get('subvencao')}</th>
        <th>{data.get('tipoSubvencao')}</th>
        <th>{DateUtils.formatDateTimeShort(data.get('dataCelebracao'))}</th>
        <th>{data.get('nomeBeneficiario')}</th>
        <th className="">{data.get('cnpj')}</th>
        <th
          style={{
            textAlign: 'right'
          }}
        >
          <NumberFormatter value={data.get('valorPrevisto')} />
        </th>
        <th
          style={{
            textAlign: 'right'
          }}
        >
          <NumberFormatter value={ValorAtual} />
        </th>
        <th
          style={{
            textAlign: 'right'
          }}
        >
          <NumberFormatter value={data.get('valorLiberado')} />
        </th>
        <th
          style={{
            textAlign: 'right'
          }}
        >
          <NumberFormatter value={saldoAtualizado} />
        </th>
      </TableActions>
    );
  };

  renderTipoSubvencao = data => {
    return (
      <option key={data.get('id')} value={data.get('id')}>
        {data.get('id') + ' - ' + data.get('descricao')}
      </option>
    );
  };

  renderAnexos = data => {
    const key = data.get('id');
    const tamanho = NumberUtils.formatBytes(data.get('tamanhoArquivo'));
    var nowDate = new Date();
    var situacao =
      data.get('ativo') === 'N' ||
      DateUtils.formatDateTimeShort(data.get('dataValidade')) <
        DateUtils.formatDateTimeShort(nowDate)
        ? 'Vencido'
        : 'Válido';

    return (
      <TableActions
        ref="row"
        key={key}
        data={data.get('id')}
        onDownload={
          situacao === 'Vencido' ? null : data.get('id') && downloadArquivo
        }
      >
        <td id="nomeArquivo">{`${data.get('nomeArquivo')} ${tamanho}`}</td>
        <td id="dataArquivo">
          {DateUtils.formatDateTimeShort(data.get('dataArquivo'))}
        </td>
        <td id="situacao">{situacao}</td>
      </TableActions>
    );
  };

  render() {
    const {
      page,
      filtros,
      location,
      tipoSubvencao,
      anexosCertidao,
      filtrosAutenticacao,
      paramConsultaAutenticidade
    } = this.props;
    return (
      <section>
        <PageHeader location={location} />

        {paramConsultaAutenticidade == 'S' && (
          <Panel
            isForm
            mobileClose
            title="Consulta Autenticidade"
            ref="panelFiltroSubvencoes"
          >
            <form>
              <Col xs={8} sm={4} md={4}>
                <Input
                  id="cnpjAutenticacao"
                  name="cnpjAutenticacao"
                  ref="cnpjAutenticacao"
                  placeholder="CNPJ - OSC"
                  label="CNPJ - OSC"
                  type="text"
                  onChange={this.onChangeFiltro}
                  value={filtrosAutenticacao.get('cnpjAutenticacao')}
                />
              </Col>
              <Col xs={12} sm={8} md={8}>
                <Input
                  id="chaveAutenticacao"
                  name="chaveAutenticacao"
                  ref="chaveAutenticacao"
                  placeholder="Chave de Autenticação"
                  label="Chave de Autenticação"
                  type="text"
                  onChange={this.onChangeFiltro}
                  value={filtrosAutenticacao.get('chaveAutenticacao')}
                />
              </Col>

              <Col xs={12} sm={4} md={4}>
                <FilterButtons
                  idBtnReset="btnResetFilter"
                  onConsulta={this.onConsultaCertidaoAnexo}
                  onReset={this.onResetFiltroAutenticacao}
                />
              </Col>

              <table className="panel-table striped fancy">
                <TableHeader
                  empty={anexosCertidao.isEmpty()}
                  text={
                    this.state.isPesquisaAutenticacao &&
                    'Certidão não encontrada! Entre em contato com o Controle Interno do Município.'
                  }
                >
                  <tr>
                    <th>Nome Arquivo</th>
                    <th>Data Arquivo</th>
                    <th>Situação</th>
                    <th></th>
                  </tr>
                </TableHeader>
                <tbody>{anexosCertidao.map(this.renderAnexos)}</tbody>
              </table>
            </form>
          </Panel>
        )}

        <Panel isForm mobileClose title="Consulta" ref="panelFiltroSubvencoes">
          <form>
            <Row>
              <Col xs={6} sm={4} md={4}>
                <Input
                  id="tipoSubvencao"
                  name="tipoSubvencao"
                  ref="tipoSubvencao"
                  placeholder="Identificação da Parceria"
                  label="Identificação da Parceria"
                  type="select"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('tipoSubvencao')}
                >
                  <option value="">Selecione</option>
                  {tipoSubvencao && tipoSubvencao.map(this.renderTipoSubvencao)}
                </Input>
              </Col>
              <Col xs={6} sm={2} md={2}>
                <DatePicker
                  id="dataAssinatura"
                  name="dataAssinatura"
                  ref="dataAssinatura"
                  placeholder="Data Assinatura"
                  label="Data Assinatura"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('dataAssinatura')}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={8} sm={4} md={4}>
                <Input
                  id="cnpj"
                  name="cnpj"
                  ref="cnpj"
                  placeholder="CNPJ - OSC"
                  label="CNPJ - OSC"
                  type="text"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('cnpj')}
                />
              </Col>
              <Col xs={12} sm={8} md={8}>
                <Input
                  id="nomeBeneficiario"
                  name="nomeBeneficiario"
                  ref="nomeBeneficiario"
                  placeholder="Nome da Organização Social Civil - OSC"
                  label="Nome da Organização Social Civil - OSC"
                  type="text"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('nomeBeneficiario')}
                />
              </Col>
              <Col xs={12} sm={12} md={12}>
                <Input
                  id="objeto"
                  name="objeto"
                  ref="objeto"
                  placeholder="Descrição do Objeto"
                  label="Descrição do Objeto"
                  type="text"
                  onChange={this.onChangeFiltro}
                  value={filtros.get('objeto')}
                />
              </Col>
              <Col xs={12} sm={4} md={4}>
                <FilterButtons
                  idBtnReset="btnResetFilter"
                  onConsulta={this.onConsultaSubvencoes}
                  onReset={this.onResetFiltro}
                />
              </Col>
            </Row>
          </form>
        </Panel>

        <NotasExplicativas pathname={this.props.location.pathname} />
        {page.get('content') && (
          <Panel isForm tableResponsive expansible={false}>
            <Row>
              <Col xs={6}>
                <UltimaAtualizacaoByTable
                  modulo="3"
                  table="subvencaosocial"
                  column="dataultimaatualizacao"
                />
              </Col>
              <Col xs={6} />
            </Row>

            <Row>
              <Col xs={6} />
              <Col xs={6}>
                <ExportButtonGroup onClick={this.onExporta} visible={true} />
              </Col>
            </Row>

            <div className="panel-table-outer">
              <table className="panel-table striped fancy">
                <TableHeader
                  empty={page.get('content').isEmpty()}
                  text="Sem registros"
                >
                  <tr>
                    <TableHeaderItem
                      field="subvencao"
                      label="Subvenção"
                      page={page}
                      searchWithPage={this.onConsultaWithPage}
                      alignment="left"
                    />
                    <TableHeaderItem
                      field="tipoSubvencao"
                      label="Identificação da Parceria"
                      page={page}
                      searchWithPage={this.onConsultaWithPage}
                      alignment="left"
                    />
                    <TableHeaderItem
                      field="dataInicioConvenio"
                      label="Data Assinatura"
                      page={page}
                      searchWithPage={this.onConsultaWithPage}
                      alignment="left"
                    />
                    <TableHeaderItem
                      field="nomeBeneficiario"
                      label="Nome da Organização Social Civil"
                      page={page}
                      searchWithPage={this.onConsultaWithPage}
                      alignment="left"
                    />
                    <TableHeaderItem
                      field="cnpj"
                      label="CNPJ"
                      page={page}
                      searchWithPage={this.onConsultaWithPage}
                      alignment="left"
                    />
                    <TableHeaderItem
                      field="valorPrevisto"
                      label="Valor Inicial"
                      page={page}
                      searchWithPage={this.onConsultaWithPage}
                      alignment="right"
                    />
                    <TableHeaderItem
                      field="valorAditivo"
                      label="Valor Atual"
                      page={page}
                      searchWithPage={this.onConsultaWithPage}
                      alignment="right"
                    />
                    <TableHeaderItem
                      field="valorLiberado"
                      label="Valores Liberados"
                      page={page}
                      searchWithPage={this.onConsultaWithPage}
                      alignment="right"
                    />
                    <TableHeaderItem
                      field="saldoAtualizado"
                      label="Saldo"
                      page={page}
                      searchWithPage={this.onConsultaWithPage}
                      alignment="right"
                    />
                    <th />
                  </tr>
                </TableHeader>
                <tbody>{page.get('content').map(this.renderSubvencoes)}</tbody>
              </table>
            </div>

            <SearchPagination
              page={page}
              searchWithPage={this.onConsultaWithPage}
            />
          </Panel>
        )}
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    filtros: state.crud.getIn(['filtroSubvencao', 'currentRecord'], Map({})),
    filtrosAutenticacao: state.crud.getIn(
      ['filtrosAutenticacao', 'currentRecord'],
      Map({})
    ),
    page: state.subvencao.get('subvencoes'),
    tipoSubvencao: state.subvencao.get('tipoSubvencao'),
    anexosCertidao: state.subvencao.get('anexosCertidao'),
    paramConsultaAutenticidade: parametroService.getValorParametro(
      state,
      PARAM_CONSULTA_AUTENTICIDADE_SUBVENCOES_TERMOS
    )
  };
}

const allActions = {
  ...actions,
  onChange,
  resetCrud,
  downloadArquivo,
  addErrorNotification
};

export default connect(mapStateToProps, allActions)(FormSubvencoes);
