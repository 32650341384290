import * as types from './Types.js';

export function findAditivo(
  entidade,
  exercicio,
  subvencao,
  anoSubvencao,
  numeroAditivo,
  anoAditivo,
  aditivoId
) {
  const aditivo = aditivoId ? `&aditivoId=${aditivoId}` : ''; 
  return ({ fetch }) => ({
    type: types.ADITIVO,
    payload: {
      promise: fetch(
        `/api/subvencoes/aditivo/detalhe?entidade=${entidade}&exercicio=${exercicio}&subvencao=${subvencao}&anoSubvencao=${anoSubvencao}&numeroAditivo=${numeroAditivo}&anoAditivo=${anoAditivo}`+aditivo
      )
    }
  });
}

export function findArquivos(
  entidade,
  exercicio,
  subvencao,
  anoSubvencao,
  numeroAditivo,
  anoAditivo,
  aditivoId
) {
  const aditivo = aditivoId ? `&aditivoId=${aditivoId}` : ''; 
  return ({ fetch }) => ({
    type: types.ADITIVO_ARQUIVOS,
    payload: {
      promise: fetch(
        `/api/subvencoes/aditivo/arquivos?entidade=${entidade}&exercicio=${exercicio}&subvencao=${subvencao}&anoSubvencao=${anoSubvencao}&numeroAditivo=${numeroAditivo}&anoAditivo=${anoAditivo}`+aditivo
      )
    }
  });
}
