import ExportButtonGroup from 'portaltransparencia-common/components/ExportButtonGroup.react.js';
import NumberFormatter from 'portaltransparencia-common/components/NumberFormatter.react.js';
import SumListField from 'portaltransparencia-common/components/SumListField.react.js';
import TableActions from 'portaltransparencia-common/components/TableActions.react.js';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react';
import configuration from 'portaltransparencia-common/config/Config.js';
import {
  PARAM_DIVIDAATIVA_CPFCNPJ_COMPLETO,
  PARAM_VALOR_DIVIDA_CONTRIBUINTE
} from 'portaltransparencia-common/parametros/ParametrosPortalTransparencia';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Component from 'react-pure-render/component';
import { connect } from 'react-redux';

import ParametroService from '../parametros/ParametroService.js';
import UltimaAtualizacao from '../ultimaatualizacao/Form.react.js';
import * as actions from './Actions.js';
import DividaAtivaContribuinteListagem from './DividaAtivaContribuinteListagem.react';
import { addErrorNotification } from 'portaltransparencia-common/ui/Actions.js';

export class DividaAtivaListagem extends Component {
  static propTypes = {
    dividas: PropTypes.object.isRequired,
    findDividasAtivasContribuinte: PropTypes.func.isRequired,
    dividasContribuinte: PropTypes.object.isRequired,
    paramValorDividaAtiva: PropTypes.string,
    paramMostrarCpfCnpj: PropTypes.string,
    filter: PropTypes.string,
    valoTotalDividaTiva: PropTypes.number,
    addErrorNotification: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      showDetail: false
    };
  }

  renderDividasAtivas = data => {
    const { paramValorDividaAtiva, paramMostrarCpfCnpj } = this.props;
    const key = data.get('nome') + '_' + data.get('cnpjCpfDB');
    const showValor = paramValorDividaAtiva == 'S';

    return (
      <TableActions
        ref="row"
        key={key}
        data={data}
        onView={this.onViewDetalhes}
        showView={showValor}
      >
        <td>{data.get('nome')}</td>
        <td>
          {paramMostrarCpfCnpj === 'S'
            ? data.get('cnpjCpfDB')
            : data.get('cnpjCpf')}
        </td>
        {showValor && (
          <td className="right">
            <NumberFormatter value={data.get('valor')} />
          </td>
        )}
      </TableActions>
    );
  };

  onViewDetalhes = item => {
    const { findDividasAtivasContribuinte } = this.props;
    findDividasAtivasContribuinte(item.get('cnpjCpfDB')).then(() =>
      this.openDetail()
    );
  };

  openDetail = () => {
    this.setState({
      showDetail: true
    });
  };

  onCloseDetail = () => {
    this.setState({
      showDetail: false
    });
  };

  onExporta = (exportType, openTab, windowParams) => {
    const { apiURL } = configuration;
    const {
      filter,
      paramValorDividaAtiva,
      paramMostrarCpfCnpj,
      dividasContribuinte,
      addErrorNotification
    } = this.props;
    const showValor = paramValorDividaAtiva == 'S';
    const showCpfCnpjCompleto = paramMostrarCpfCnpj == 'S';

    if (dividasContribuinte.size > 0) {
      let filtro =
        filter +
        '&showValor=' +
        showValor +
        '&exportType=' +
        exportType +
        '&showCpfCnpjCompleto=' +
        showCpfCnpjCompleto;

      window.open(
        `${apiURL}/api/dividas-ativas/report?${filtro}`,
        openTab,
        windowParams
      );
    } else {
      addErrorNotification(
        'A consulta não apresentou resultado. Não é possível exportar para o formato selecionado!'
      );
    }
  };

  render() {
    const {
      dividas,
      dividasContribuinte,
      paramValorDividaAtiva,
      valoTotalDividaTiva
    } = this.props;

    const { showDetail } = this.state;
    const showValor = paramValorDividaAtiva == 'S';

    return (
      <div>
        <DividaAtivaContribuinteListagem
          dividas={dividasContribuinte}
          onClose={this.onCloseDetail}
          show={showDetail}
        />

        <Row>
          <Col sm={6}>
            <UltimaAtualizacao modulo="3" />
          </Col>
          <Col sm={6}>
            <ExportButtonGroup onClick={this.onExporta} visible={true} />
          </Col>
        </Row>

        <div className="panel-table-outer">
          <table className="panel-table striped fancy">
            <TableHeader empty={dividas.isEmpty()}>
              <tr>
                <th>Nome</th>
                <th>CPF/CNPJ</th>
                {showValor && <th className="right">Valor</th>}
                <th />
              </tr>
            </TableHeader>
            <tbody>{dividas.map(this.renderDividasAtivas)}</tbody>
            {showValor && !dividas.isEmpty() && (
              <tfoot>
                <tr>
                  <td />
                  <td />
                  <td>
                    <i>Total da Página: </i>{' '}
                    <SumListField fieldName="valor" list={dividas} />
                  </td>
                  <td>
                    <i>
                      Total geral:
                      <NumberFormatter value={valoTotalDividaTiva} />
                    </i>
                    {'  '}
                  </td>
                </tr>
              </tfoot>
            )}
          </table>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    dividasContribuinte: state.dividaAtiva.get('dividasContribuinte'),
    paramValorDividaAtiva: ParametroService.getValorParametro(
      state,
      PARAM_VALOR_DIVIDA_CONTRIBUINTE
    ),
    paramMostrarCpfCnpj: ParametroService.getValorParametro(
      state,
      PARAM_DIVIDAATIVA_CPFCNPJ_COMPLETO
    )
  };
}

const allActions = {
  ...actions,
  addErrorNotification
};

export default connect(mapStateToProps, allActions)(DividaAtivaListagem);
