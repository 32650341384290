import NumberFormatter from 'portaltransparencia-common/components/NumberFormatter.react.js';
import TableActions from 'portaltransparencia-common/components/TableActions.react.js';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react.js';
import DateUtils from 'portaltransparencia-common/libs/DateUtils.js';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';

export class SubvencaoAditivos extends Component {
  static propTypes = {
    aditivos: PropTypes.object.isRequired
  };

  onViewDetalhes = aditivo => {
    const entidade = aditivo.get('entidade');
    const exercicio = aditivo.get('exercicio');
    const subvencao = aditivo.get('subvencao');
    const anoSubvencao = aditivo.get('anoSubvencao');
    const numeroAditivo = aditivo.get('numeroAditivo');
    const anoAditivo = aditivo.get('anoAditivo');
    const aditivoId = aditivo.get('aditivoId') ? `&aditivoId=${aditivo.get('aditivoId')}` : ''; 

    this.props.history.push(
      `/subvencoes/aditivo/detalhe?entidade=${entidade}&exercicio=${exercicio}&subvencao=${subvencao}&anoSubvencao=${anoSubvencao}&numeroAditivo=${numeroAditivo}&anoAditivo=${anoAditivo}`+aditivoId
    );
  };

  renderAditivos = data => {
    const key = data.get('numeroAditivo') + '_' + data.get('anoAditivo');

    return (
      <TableActions
        ref="row"
        key={key}
        data={data}
        onMore={this.onViewDetalhes}
      >
        <th>{data.get('numeroAditivo')} </th>
        <th>{data.get('anoAditivo')}</th>
        <th>{data.get('tipo')}</th>
        <th>{data.get('descricao')}</th>
        <th>{DateUtils.formatDateTimeShort(data.get('dataTermo'))}</th>
        <th>{DateUtils.formatDateTimeShort(data.get('dataFimVigencia'))}</th>
        <th>{data.get('numeroDiarioOficial')}</th>
        <th>{data.get('anoDiarioOficial')}</th>
        <th>
          {DateUtils.formatDateTimeShort(data.get('dataPublicacaoDiario'))}
        </th>
        <th>{data.get('nome')}</th>
        <th>
          <NumberFormatter value={data.get('valor')} />
        </th>
        <th>
          <NumberFormatter value={data.get('valorContraPartida')} />
        </th>
      </TableActions>
    );
  };

  render() {
    const { aditivos } = this.props;

    return (
      <div className="panel-table-outer">
        <table className="panel-table striped fancy">
          <TableHeader empty={aditivos.isEmpty()}>
            <tr>
              <th>Número</th>
              <th>Ano</th>
              <th>Tipo Aditivo</th>
              <th>Descrição</th>
              <th>Data Termo</th>
              <th>Data Fim Vigência</th>
              <th>Nº Diário</th>
              <th>Ano Diário</th>
              <th>Data Pub. Diário</th>
              <th>Fornecedor</th>
              <th>Valor</th>
              <th>Valor Contrapartida</th>
              <th></th>
            </tr>
          </TableHeader>
          <tbody>{aditivos.map(this.renderAditivos)}</tbody>
        </table>
      </div>
    );
  }
}

export default SubvencaoAditivos;
