// import { routerReducer as routing } from 'react-router-redux';
import crud from 'portaltransparencia-common/crud/Reducer.js';
import info from 'portaltransparencia-common/info/Reducer.js';
import ui from 'portaltransparencia-common/ui/Reducer.js';
import { combineReducers } from 'redux';

import adiantamento from '../adiantamento/Reducer.js';
import aditivos from '../aditivos/Reducer.js';
import adjudicacao from '../adjudicacoes/Reducer.js';
import afastamentos from '../afastamento/Reducer.js';
import agendaPagamento from '../agendaprevisaopagamento/Reducer.js';
import ajudaCusto from '../ajudacusto/Reducer.js';
import almoxarifado from '../almoxarifado/Reducer.js';
import alvara from '../alvara/Reducer.js';
import aptopagamento from '../aptospagamentos/Reducer.js';
import arrecadacao from '../arrecadacao/Reducer.js';
import atendimento from '../atendimento/Reducer.js';
import audienciapublica from '../audienciasPublicas/Reducer.js';
import autorizacao from '../autorizacoes/Reducer.js';
import avaliacaoppa from '../avaliacaoppa/Reducer.js';
import cargo from '../cargos/Reducer.js';
import cartaServicos from '../cartaServicos/Reducer.js';
import cedido from '../cedidos/Reducer.js';
import comprasDiretaFornecedor from '../comprasdiretasfornecedor/Reducer.js';
import compradiretaitem from '../comprasdiretasitens/Reducer.js';
import conselho from '../conselhos/Reducer.js';
import consultaSic from '../consultasic/Reducer.js';
import contato from '../contato/Reducer.js';
import contrato from '../contratos/Reducer.js';
import convenio from '../convenios/Reducer.js';
import creditosAdicionais from '../creditosadicionais/Reducer.js';
import dadosAbertos from '../dadosAbertos/Reducer.js';
import dashboard from '../dashboard/Reducer.js';
import despesaPorNivel from '../despesa/Reducer.js';
import despesasDetalhe from '../despesaDetalhada/Reducer.js';
import diaria from '../diarias/Reducer.js';
import diarioReducer from '../diario/Reducer.js';
import dividaAtiva from '../dividaativa/Reducer.js';
import documento from '../documentosSaude/Reducer.js';
import emLiquidacao from '../emliquidacao/Reducer.js';
import empenhos from '../empenhos/Reducer.js';
import entidade from '../entidades/Reducer.js';
import userInfoReducer from '../entidades/UserInfoReducer.js';
import escala from '../escalasProfissionais/Reducer.js';
import esic from '../esic/Reducer.js';
import filaEsperaAtendimento from '../filaEsperaAtendimento/Reducer.js';
import fornecedoresAPagar from '../fornecedores/Reducer.js';
import glossario from '../glossario/Reducer.js';
import homologacao from '../homologacoes/Reducer.js';
import horasExtras from '../horasExtras/Reducer.js';
import leisatos from '../leiseatos/Reducer.js';
import leiesic from '../leisregemportal/Reducer.js';
import licitacao from '../licitacoes/Reducer.js';
import linkutil from '../linkutil/Reducer.js';
import liquidacoes from '../liquidacoes/Reducer.js';
import medidasAdotadasCobranca from '../medidasadotadas/Reducer.js';
import menu from '../menu/Reducer.js';
import garagem from '../movimentogaragem/Reducer.js';
import notaExplicativa from '../notaexplicativa/Reducer.js';
import notaExtraorcamentaria from '../notaextraorcamentaria/Reducer.js';
import obra from '../obraspublicas/Reducer.js';
import orcamento from '../orcamento/Reducer.js';
import organograma from '../organograma/Reducer.js';
import parametros from '../parametros/Reducer.js';
import licitacoesParticipantes from '../participantes/Reducer.js';
import patrimonio from '../patrimonio/Reducer.js';
import pergunta from '../perguntas/Reducer.js';
import permutas from '../permutas/Reducer.js';
import processoSigiloso from '../processosSigilosos/Reducer.js';
import publicacao from '../publicacoes/Reducer.js';
import quadroPessoal from '../quadro-pessoal/Reducer.js';
import receita from '../receita/Reducer.js';
import receitasDetalhe from '../receitaDetalhada/Reducer.js';
import repasse from '../repasses/Reducer.js';
import responsavel from '../responsavel/Reducer.js';
import servidor from '../servidores/Reducer.js';
import servidorOxy from '../servidoresOxy/Reducer.js';
import sicfisico from '../sicfisico/Reducer.js';
import subvencao from '../subvencoes/Reducer.js';
import suspensaoFornecedor from '../suspensaoFornecedor/Reducer.js';
import aditivo from '../subvencoesAditivo/Reducer.js';
import prestacaoContas from '../subvencoesPrestacaoContas/Reducer.js';
import suprimentoFundos from '../suprimentosfundos/Reducer.js';
import totalacesso from '../totalacessos/Reducer.js';
import ultimaAtualizacao from '../ultimaatualizacao/Reducer.js';
import veiculo from '../veiculos/Reducer.js';
import verbas from '../verbas/Reducer.js';
import processos from '../processoDisciplinar/Reducer.js';
import leilgpd from '../leislgpd/Reducer.js';
import ordemcronologicapagamento from '../ordemcronologicapagamentos/Reducer.js';
import renunciaReceitas from '../renuncia-receitas/Reducer.js';
import emendaParlamentar from '../emendaParlamentar/Reducer.js';
import cotasParlamentares from '../cotas-parlamentares/Reducer.js';
import terceirizados from '../terceirizados/Reducer.js';
import contabRenunciaReceitas from '../contab-renuncia-receitas/Reducer.js';
import acessibilidade from '../acessibilidade/Reducer.js';
import restosAPagar from '../restosapagar/Reducer.js';
import recurso from '../recursos/Reducer.js';
import pca from '../pca/Reducer.js';
import acordoSemRecurso from '../acordoSemRecurso/Reducer.js';
import estrutura from '../estrutura-organizacional/Reducer.js';
import servidoresCedidos from '../cedidos-webservice/Reducer.js';

const appReducer = combineReducers({
  auth: userInfoReducer,
  ui,
  info,
  crud,
  entidade,
  menu,
  almoxarifado,
  totalacesso,
  atendimento,
  pergunta,
  patrimonio,
  publicacao,
  licitacao,
  leisatos,
  ultimaAtualizacao,
  parametros,
  leiesic,
  veiculo,
  despesaPorNivel,
  obra,
  contrato,
  diaria,
  adiantamento,
  servidor,
  servidorOxy,
  convenio,
  empenhos,
  fornecedoresAPagar,
  comprasDiretaFornecedor,
  repasse,
  responsavel,
  cedido,
  esic,
  dividaAtiva,
  receita,
  garagem,
  organograma,
  orcamento,
  dashboard,
  contato,
  sicfisico,
  compradiretaitem,
  liquidacoes,
  cargo,
  ajudaCusto,
  suprimentoFundos,
  aptopagamento,
  linkutil,
  avaliacaoppa,
  medidasAdotadasCobranca,
  consultaSic,
  glossario,
  notaExplicativa,
  arrecadacao,
  filaEsperaAtendimento,
  conselho,
  escala,
  horasExtras,
  agendaPagamento,
  licitacoesParticipantes,
  subvencao,
  alvara,
  emLiquidacao,
  notaExtraorcamentaria,
  homologacao,
  suspensaoFornecedor,
  aditivos,
  adjudicacao,
  despesasDetalhe,
  receitasDetalhe,
  autorizacao,
  creditosAdicionais,
  prestacaoContas,
  aditivo,
  permutas,
  processoSigiloso,
  diarioReducer,
  quadroPessoal,
  documento,
  audienciapublica,
  cartaServicos,
  dadosAbertos,
  afastamentos,
  verbas,
  processos,
  leilgpd,
  ordemcronologicapagamento,
  renunciaReceitas,
  emendaParlamentar,
  cotasParlamentares,
  terceirizados,
  contabRenunciaReceitas,
  acessibilidade,
  restosAPagar,
  recurso,
  pca,
  acordoSemRecurso,
  estrutura,
  servidoresCedidos
});

export default function rootReducer(state, action) {
  if (action.type === 'EXERCICIO_LOGADO_START') {
    state = {
      routing: state.routing // Routing state has to be reused.
    };
  }

  return appReducer(state, action);
}
