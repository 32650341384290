import * as actions from './Actions.js';

import {
  PARAM_CONTRATO_EMPENHOS,
  PARAM_CONTRATO_ITENS_CONTRATADOS,
  PARAM_DOCUMENTOS_CONTRATOS
} from 'portaltransparencia-common/parametros/ParametrosPortalTransparencia';

import Arquivo from '../arquivo/Arquivo.react.js';
import BotaoImprimir from 'portaltransparencia-common/components/BotaoImprimir.react.js';
import CabecalhoEntidade from '../entidades/CabecalhoEntidade.react.js';
import Component from 'react-pure-render/component';
import ContratoDetalheAditivo from './ContratoDetalheAditivo.react.js';
import ContratoDetalheInformacao from './ContratoDetalheInformacao.react.js';
import ContratoDetalheItens from './ContratoDetalheItens.react.js';
import ContratoDetalheResponsavel from './ContratoDetalheResponsavel.react.js';
import Empenhos from './../empenhos/Form.react.js';
import Helmet from 'react-helmet';
import PageHeader from '../components/PageHeader.react.js';
import Panel from 'portaltransparencia-common/components/Panel.react.js';
import ParametroService from '../parametros/ParametroService.js';
import PropTypes from 'prop-types';
import React from 'react';
import Tab from 'portaltransparencia-common/components/Tab.react.js';
import Tabs from 'portaltransparencia-common/components/Tabs.react.js';
import { connect } from 'react-redux';
import { downloadArquivo } from '../arquivo/Actions.js';
import { getValueFromUrlSearchParams } from 'portaltransparencia-common/libs/URLUtils.js';

export class ContratoDetalhe extends Component {
  static propTypes = {
    aditivos: PropTypes.object.isRequired,
    arquivos: PropTypes.object.isRequired,
    contrato: PropTypes.object.isRequired,
    responsaveis: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    downloadArquivo: PropTypes.func.isRequired,
    findAditivos: PropTypes.func.isRequired,
    findArquivos: PropTypes.func.isRequired,
    findContrato: PropTypes.func.isRequired,
    findLicitacao: PropTypes.func.isRequired,
    findResponsaveis: PropTypes.func.isRequired,
    licitacao: PropTypes.object,
    paramExibeDocumentos: PropTypes.string,
    paramExibeItens: PropTypes.string,
    paramExibeEmpenhos: PropTypes.string,
    uf: PropTypes.string
  };

  componentDidMount() {
    const {
      location,
      findAditivos,
      findContrato,
      findArquivos,
      findResponsaveis,
      findLicitacao
    } = this.props;

    const entidadeContrato = getValueFromUrlSearchParams(location, 'entidade');
    const exercicioContrato = getValueFromUrlSearchParams(
      location,
      'exercicio'
    );
    const codigoContrato = getValueFromUrlSearchParams(location, 'contrato');
    const tipoAtoContrato = getValueFromUrlSearchParams(location, 'tipoAto');

    findContrato(
      entidadeContrato,
      exercicioContrato,
      codigoContrato,
      tipoAtoContrato
    ).then(respostaContrato => {
      const {
        entidade,
        exercicio,
        contrato,
        tipoAto,
        entidadeLicitacao,
        exercicioLicitacao,
        tipoLicitacao,
        licitacao,
        numeroContrato
      } = respostaContrato?.value;

      findAditivos(entidade, exercicio, contrato, tipoAto);
      findResponsaveis(entidade, exercicio, contrato, tipoAto);
      findArquivos(entidade, exercicio, contrato, tipoAto);

      if (
        numeroContrato &&
        entidadeLicitacao &&
        exercicioLicitacao &&
        tipoLicitacao &&
        licitacao
      ) {
        findLicitacao(
          entidadeLicitacao,
          exercicioLicitacao,
          tipoLicitacao,
          licitacao
        );
      }
    });
  }

  render() {
    const {
      aditivos,
      contrato,
      responsaveis,
      downloadArquivo,
      arquivos,
      licitacao,
      paramExibeDocumentos,
      paramExibeItens,
      paramExibeEmpenhos,
      uf
    } = this.props;

    let empenhoFilter;
    if (licitacao?.get('idTipoCompra') === 'OBRA') {
      let numeroContrato = contrato.get('numeroContrato');
      let anoContrato = contrato.get('exercicio');
      let entidade = contrato.get('entidade');
      if (numeroContrato && anoContrato && entidade) {
        empenhoFilter = `?search=contrato==${numeroContrato} and anoContrato==${anoContrato} and id.entidade==${entidade}`;
      }
    }

    return (
      <div>
        <Helmet
          title={`Contratos / Atas - #${contrato.get(
            'numeroContrato'
          )} ${contrato.get('nome')}`}
        />

        <BotaoImprimir />

        <PageHeader
          location={this.props.location}
          textoDetalhe={`Contrato: ${contrato.get(
            'numeroContrato'
          )}/${contrato.get('exercicio')}`}
        />

        <CabecalhoEntidade />

        <ContratoDetalheInformacao contrato={contrato} />

        {!responsaveis.isEmpty() && (
          <ContratoDetalheResponsavel
            responsaveis={responsaveis}
            descricaoTipoAto={contrato.get('descricaoTipoAto')}
          />
        )}

        {paramExibeDocumentos && paramExibeDocumentos === 'S' && (
          <Panel isTable tableResponsive title="Anexos">
            <Arquivo arquivos={arquivos} onDownload={downloadArquivo} />
          </Panel>
        )}

        <ContratoDetalheAditivo
          aditivos={aditivos}
          uf={uf}
          history={this.props.history}
          onDownload={downloadArquivo}
        />

        {!(paramExibeItens !== 'S' && paramExibeEmpenhos !== 'S') &&
          empenhoFilter && (
            <Panel expansible={false} isTable tableResponsive>
              <Tabs scrollVisibled>
                <Tab
                  title="Itens Contratados"
                  hide={paramExibeItens !== 'S'}
                  active
                >
                  <h4 className="mt-xs mb-xs center">
                    <b>Itens contratados</b>
                  </h4>

                  <ContratoDetalheItens contrato={contrato} />
                </Tab>

                <Tab
                  title="Empenhos"
                  hide={paramExibeEmpenhos !== 'S'}
                  active={paramExibeItens !== 'S'}
                >
                  <h4 className="mt-xs mb-xs center">
                    <b>Empenhos vinculados ao contrato</b>
                  </h4>

                  <Empenhos
                    filtroParam={empenhoFilter}
                    history={this.props.history}
                    disableHeader
                  />
                </Tab>
              </Tabs>
            </Panel>
          )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    aditivos: state.contrato.get('aditivos'),
    contrato: state.contrato.get('contrato'),
    arquivos: state.contrato.get('arquivos'),
    licitacao: state.contrato.get('licitacao'),
    responsaveis: state.contrato.get('responsaveis'),
    uf: state.entidade.getIn(['entidadeSelecionada', 'uf']),
    paramExibeDocumentos: ParametroService.getValorParametro(
      state,
      PARAM_DOCUMENTOS_CONTRATOS
    ),
    paramExibeItens: ParametroService.getValorParametro(
      state,
      PARAM_CONTRATO_ITENS_CONTRATADOS
    ),
    paramExibeEmpenhos: ParametroService.getValorParametro(
      state,
      PARAM_CONTRATO_EMPENHOS
    )
  };
}

const allActions = {
  ...actions,
  downloadArquivo
};

export default connect(mapStateToProps, allActions)(ContratoDetalhe);
