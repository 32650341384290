import Panel from 'portaltransparencia-common/components/Panel.react.js';
import TableActions from 'portaltransparencia-common/components/TableActions.react.js';
import TableHeader from 'portaltransparencia-common/components/TableHeader.react.js';
import DateUtils from 'portaltransparencia-common/libs/DateUtils.js';
import PropTypes from 'prop-types';
import React from 'react';
import Component from 'react-pure-render/component';

export class ContratoDetalheResponsavel extends Component {
  static propTypes = {
    responsaveis: PropTypes.object.isRequired
  };

  renderResponsaveis = data => {
    const key =
      data.get('entidade') +
      '_' +
      data.get('exercicio') +
      '_' +
      data.get('contrato') +
      '_' +
      data.get('sequencia');

    return (
      <TableActions ref="row" key={key} data={data}>
        <td>{data.get('ocupante')}</td>
        <td>{DateUtils.transform(data.get('dataInicio'))}</td>
        <td>{DateUtils.transform(data.get('dataTermino'))}</td>
        <td>{data.get('descricaoCargo')}</td>
        <td>{data.get('obsAtoDesignacao')}</td>
      </TableActions>
    );
  };

  render() {
    const { responsaveis } = this.props;
    const titulo = 'Fiscalização ' + this.props.descricaoTipoAto;

    return (
      <section>
        <Panel title={titulo} isTable tableResponsive>
          <div className="panel-table-outer">
            <table className="panel-table striped fancy">
              <TableHeader
                empty={responsaveis.isEmpty()}
                text="Sem Fiscais do Contrato."
              >
                <tr>
                  <th>Nome</th>
                  <th>Data Início</th>
                  <th>Data Término</th>
                  <th>Cargo</th>
                  <th>Ato de Designação</th>
                  <th></th>
                </tr>
              </TableHeader>
              <tbody>{responsaveis.map(this.renderResponsaveis)}</tbody>
            </table>
          </div>
        </Panel>
      </section>
    );
  }
}

export default ContratoDetalheResponsavel;
